import React from "react"

import Img from 'gatsby-image'
import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"

const BillPage = ({data}) => {
    const meta = data.graphAPI.contentBilling.meta
    const content = data.graphAPI.contentBilling.content
    return (
        <Layout>
            <SEO title={meta.title} />
                    <div className="columns">
                    <div className="column is-8">

                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <Content content={content} />
                    <div className="segment">
                    <div className="content-section">
                        <h2>Service Rate Schedule</h2>
                        {data.graphAPI.serviceRate.services.map(service => {
                            console.log(service.rateTiers)
                            return (
                            (<div className="service-box">
                                <h4>{service.title}</h4>
                                <table className="table" style={{width: 'auto'}}> 
                                    <tbody>
                                        {service.rateTiers.map((tier, key) => {
                                            function getRate(rate, key, service) {
                                                function numberWithCommas(x) {
                                                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                }
                                                console.log(rate.title)
                                                if(key === 0 && rate.to === -1){
                                                    if(rate.type === "fixed") {
                                                        return `Base Rate for Service`
                                                    } else if(rate.type === "minimum") {
                                                        return `Minimum Rate for Service`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }
                                                }

                                                if(rate.title !== undefined) {
                                                    if(rate.type === "fixed" || rate.type === "tax") {
                                                        return `${rate.title}`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }

                                                }

                                                if(rate.type === "per1K") {
                                                    if(rate.to === -1) {
                                                        return `Rate per 1,000 gallons metered for usage above ${numberWithCommas(rate.from)} gallons`
                                                    }
                                                    return `Rate per 1,000 gallons metered for usage between ${numberWithCommas(rate.from)} gallons and ${numberWithCommas(rate.to)} gallons`
                                                }

                                                return `Undefined Rate`


                                            }
                                            return (
                                            <tr key={key}>
                                                {tier.type === "tax" ? (
                                                    <td style={{textAlign: 'right', width: '100px'}}>{(tier.rate*100).toFixed(2)} %</td>
                                                ) : (
                                                    <td style={{textAlign: 'right', width: '100px'}}>$ {tier.rate.toFixed(2)}</td>
                                                )}
                                                <td>{getRate(tier, key, service.title)}</td>

                                            </tr>
                                        )})}

                                    </tbody>
                                </table>
                            </div>)
                        )})}

                        <article class="message is-primary">
                            <div class="message-header">North Fort Bend Water Authority Fees</div>
                            <div class="message-body"><p>In order to comply with the legislative mandate to reduce groundwater usage, the District, along with other municipal utility districts in the area, is part of the North Fort Bend Water Authority (the "Authority") Groundwater Reduction Plan ("GRP"). To comply with the groundwater reduction mandate, the Authority is responsible for constructing the necessary water delivery infrastructure to deliver surface water to the areas within the Authority. In order to design, construct, and operate the necessary infrastructure, the Authority assesses fees to the well owners/municipal utility districts within the Authority's boundaries based on the amount of water pumped by their wells or the amount of surface water received from the Authority. Accordingly, the Authority charges the District a fee for each 1,000 gallons of groundwater withdrawn by the District (the "GRP Fee"), which GRP Fee may be increased by the Authority from time to time. The District then passes the GRP Fees to cover related District costs, through to the District's users based on the amount of water they use. As such, each user within the District is billed, in addition to the District's water rates, a separate amount for each 1,000 gallons of water delivered to such user in a billing cycle that is equal to the GRP Fee charged to the District by the Authority for each 1,000 gallons of water for that period.</p></div>
                        </article>
                        <hr />
                        <h4>Notice About Confidentiality of Customer Information</h4>
                        <p>Chapter 182 of the Texas Utilities Code as amended in 2021 by House Bill 872 provides that a government-operated utility may not disclose personal information (customer's address, telephone number, and social security number) in a customer's account, or any information related to the volume or units of utility usage or amounts billed or collected for such utility usage, unless the customer elects to allow such information disclosed.</p>
                        <p>The Utilities Code requires the District to provide notice of the  customer's right to allow disclosure of his or her information. Therefore, if you wish to allow disclosure of your personal information, please contact the District Operator, Inframark, via telephone at 281-579-4500 or via email at MUDCustomerService@Inframark.com so they can provide you a disclosure form for you to complete and return.</p>
                        <p><em>NOTE: The District is allowed to disclose information in a customer's account record to federal, state, or local government officials; to District employees, officials and operations personnel; to consumer reporting agencies; to a contractor or subcontractor approved by and providing services to the District, the state, a political subdivision of the state, or the United States; or to any other provider of utility services.</em></p>
                    </div>
                    </div>

                    </div>
                    <div className="column is-3" style={{paddingTop: '11rem'}}>
                        <Img className="is-hidden-mobile" style={{width: '100%', height: '800px'}} fluid={data.faucet.childImageSharp.fluid} alt="" />
                    </div>
                    </div>
        </Layout>
    )
}

export default BillPage

export const billPageQuery = graphql`
    query billPage {
        faucet: file(relativePath: {eq: "4213043923_086bc9b7e7_o_crop.jpg"}) {
            childImageSharp {
                fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphAPI {
            contentBilling {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            serviceRate {
                services {
                    rateTiers
                    title
                    id
                }
            }
        } 
    }
`


